<template>
  <div class="about">
    <public-header />
    <div class="banner main">
      <div class="container">
       <v-breadcrumbs :items="this[menu].breadCrumb" class="main-breadcrumb">
         <template v-slot:divider>
            <img src="@/assets/icons/breadcrumb-devider.svg" alt="bread crumb" />
          </template>
        </v-breadcrumbs>
      </div>
    </div>
    <main>
      <div class="container">
        <h1 class="about-title">{{ this[menu].title }}</h1>
        <div class="about-list">
          <div class="about-list__title">
            <h2>{{ this[menu].breadCrumb[2].text }}</h2>
          </div>
          <div class="about-list__item" v-for="(step, i) in this[menu].listSteps" :key="i">
            <div class="about-list__left" >
              <div class="about__header">
                <div class="count-wrapper">
                  <span>
                    {{ i + 1 }}
                  </span>
                </div>
                <span class="step">{{ $t('step') }}</span>
              </div>
              <p class="about-list__text" v-html="step.text"></p>
            </div>
            <div class="about-list__right">
              <img
                height="271"
                @click="openImage(step.img)"
                :src="
                require(`@/assets/imgs/${step.img == 'default' ? 'default-image.svg' : step.img}`)"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
    <home-footer />
    <v-dialog
      v-model="imageShowModal"
      width="75vw"
    >
      <div class="modal-image-wrapper">
        <span
          @click="imageShowModal = false"
          class="icon icon-close modal-image-wrapper-icon-close"
        ></span>
        <img
          class="modal-image-wrapper-img"
          :src="require(`@/assets/imgs/${imageShowModalImg}`)" alt="modal image"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import PublicHeader from '@/components/PublicHeader';
import HomeFooter from '@/components/HomeFooter';
/* eslint-disable global-require */

export default {
  name: 'UsingSystem',
  components: {
    PublicHeader,
    HomeFooter,
  },
  data() {
    return {
      imageShowModal: false,
      imageShowModalImg: 'default-image.svg',
      'home-sdmx': {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('useofSearchEngineAndMetadataonMainPage'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'Asosiy_1-min.png',
            text: this.$t('aboutSystemMainStep1'),
          },
          {
            img: 'Asosiy_2-min.png',
            text: this.$t('aboutSystemMainStep2'),
          },
          {
            img: 'Asosiy_3-min.png',
            text: this.$t('aboutSystemMainStep3'),
          },
          {
            img: 'Asosiy_4-min.png',
            text: this.$t('aboutSystemMainStep4'),
          },
          {
            img: 'Asosiy_5-min.png',
            text: this.$t('aboutSystemMainStep5'),
          },
          {
            img: 'Asosiy_6-min.png',
            text: this.$t('aboutSystemMainStep6'),
          },
        ],
      },
      table: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('usingtheTable'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'Jadvaldan_Foydalanish_1-min.png',
            text: this.$t('aboutSystemTableStep1'),
          },
          {
            img: 'Jadvaldan_Foydalanish_2-min.png',
            text: this.$t('aboutSystemTableStep2'),
          },
          {
            img: 'Jadvaldan_Foydalanish_3-min.png',
            text: this.$t('aboutSystemTableStep3'),
          },
          {
            img: 'Jadvaldan_Foydalanish_4-min.png',
            text: this.$t('aboutSystemTableStep4'),
          },
        ],
      },
      reyting: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('usingtheRating'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'Reytingdan_Foydalanish_1-min.png',
            text: this.$t('aboutSystemReytingStep1'),
          },
          {
            img: 'Reytingdan_Foydalanish_2-min.png',
            text: this.$t('aboutSystemReytingStep2'),
          },
          {
            img: 'Reytingdan_Foydalanish_3-min.png',
            text: this.$t('aboutSystemReytingStep3'),
          },
          {
            img: 'Reytingdan_Foydalanish_4-min.png',
            text: this.$t('aboutSystemReytingStep4'),
          },
          {
            img: 'Reytingdan_Foydalanish_5-min.png',
            text: this.$t('aboutSystemReytingStep5'),
          },
          {
            img: 'Reytingdan_Foydalanish_6-min.png',
            text: this.$t('aboutSystemReytingStep6'),
          },
        ],
      },
      gistogramm: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('useofHistogram'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'Gistogramma_1-min.png',
            text: this.$t('aboutSystemGistogramStep1'),
          },
          {
            img: 'Gistogramma_2-min.png',
            text: this.$t('aboutSystemGistogramStep2'),
          },
          {
            img: 'Gistogramma_3-min.png',
            text: this.$t('aboutSystemGistogramStep3'),
          },
          {
            img: 'Gistogramma_4-min.png',
            text: this.$t('aboutSystemGistogramStep4'),
          },
        ],
      },
      kartogramm: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('usingCartogramma'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'KARTOGRAMMA_1-min.png',
            text: this.$t('aboutSystemKartogrammStep1'),
          },
          {
            img: 'KARTOGRAMMA_2-min.png',
            text: this.$t('aboutSystemKartogrammStep2'),
          },
          {
            img: 'KARTOGRAMMA_3-min.png',
            text: this.$t('aboutSystemKartogrammStep3'),
          },
          {
            img: 'KARTOGRAMMA_4-min.png',
            text: this.$t('aboutSystemKartogrammStep4'),
          },
        ],
      },
      grafic: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('usingtheGraph'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'GRAFIK-1-min.png',
            text: this.$t('aboutSystemGraficStep1'),
          },
          {
            img: 'GRAFIK-2-min.png',
            text: this.$t('aboutSystemGraficStep2'),
          },
          {
            img: 'GRAFIK-3-min.png',
            text: this.$t('aboutSystemGraficStep3'),
          },
          {
            img: 'GRAFIK-4-min.png',
            text: this.$t('aboutSystemGraficStep4'),
          },
        ],
      },
      comparison: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('useofComparison'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'SOLISHTIRISH-1-min.png',
            text: this.$t('aboutSystemComparisonStep1'),
          },
          {
            img: 'SOLISHTIRISH-2-min.png',
            text: this.$t('aboutSystemComparisonStep2'),
          },
          {
            img: 'SOLISHTIRISH-3-min.png',
            text: this.$t('aboutSystemComparisonStep3'),
          },
          {
            img: 'SOLISHTIRISH-4-min.png',
            text: this.$t('aboutSystemComparisonStep4'),
          },
          {
            img: 'SOLISHTIRISH-5-min.png',
            text: this.$t('aboutSystemComparisonStep5'),
          },
          {
            img: 'SOLISHTIRISH-6-min.png',
            text: this.$t('aboutSystemComparisonStep6'),
          },
          {
            img: 'SOLISHTIRISH-7-min.png',
            text: this.$t('aboutSystemComparisonStep7'),
          },
        ],
      },
      relationship: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('usingtheRelationship'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'MUNOSABAT_1-min.png',
            text: this.$t('aboutSystemRelationshipStep1'),
          },
          {
            img: 'MUNOSABAT_2-min.png',
            text: this.$t('aboutSystemRelationshipStep2'),
          },
          {
            img: 'MUNOSABAT_3-min.png',
            text: this.$t('aboutSystemRelationshipStep3'),
          },
          {
            img: 'MUNOSABAT_4-min.png',
            text: this.$t('aboutSystemRelationshipStep4'),
          },
          {
            img: 'MUNOSABAT_5-min.png',
            text: this.$t('aboutSystemRelationshipStep5'),
          },
        ],
      },
      calculation: {
        breadCrumb: [
          {
            text: this.$t('main'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/',
            },
          },
          {
            text: this.$t('aboutSystem'),
            disabled: false,
            link: true,
            exact: true,
            to: {
              path: '/about-us',
            },
          },
          {
            text: this.$t('useofCalculation'),
            disabled: true,
            link: true,
            exact: true,
          },
        ],
        listSteps: [
          {
            img: 'HISOBLASH_1-min.png',
            text: this.$t('aboutSystemCalculationStep1'),
          },
          {
            img: 'HISOBLASH_2-min.png',
            text: this.$t('aboutSystemCalculationStep2'),
          },
          {
            img: 'HISOBLASH_3-min.png',
            text: this.$t('aboutSystemCalculationStep3'),
          },
        ],
      },
      menu: this.$route.params.menu,
    };
  },
  methods: {
    openImage(url) {
      this.imageShowModalImg = url;
      this.imageShowModal = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" src="./UsingSystem.scss"></style>
